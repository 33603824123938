@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #080808;
  cursor: default;
  font-family: "Montserrat", sans-serif;
}

.ant-layout {
  background-color: #080808 !important;
}
.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hideText {
  .react-pdf__Page__textContent {
    display: none !important;
  }
  .react-pdf__Page__annotations {
    display: none !important;
  }
}
.customScrollBar {
  ::-webkit-scrollbar {
    width: 2px !important;
    height: 2px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #333;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #666;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}

.customSelected {
  .ant-select-selection-item {
    height: 55px !important;
  }
}

.makeBgBlack ::placeholder {
  color: #a7a7a7 !important;
}

.customLoginModal {
  .ant-modal-content {
    background-color: transparent !important;
    padding: 0 !important;
  }
}

.customModalPaddingZero {
  .ant-modal-content {
    padding: 0 !important;
  }
}
.ant-modal-mask {
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ) !important; /* Adjust the opacity as needed */
  backdrop-filter: blur(5px) !important;
}
.customMenuItems {
  .ant-menu-item-selected {
    background-color: #222222 !important;
    color: white !important;
    border-radius: 6px !important;
  }
}

.customTab {
  .ant-tabs-tab-active {
    color: #ff5612 !important;
  }
}

.customTable {
  .ant-table-cell {
    padding: 9px 16px !important;
  }
  .ant-table-wrapper {
    border-radius: 0px !important;
  }
}

::-webkit-scrollbar {
  width: 13px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #333;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #666;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.tableHeader {
  .ant-table-thead th {
    white-space: nowrap;
    border-radius: 0px !important;
    color: white !important;
    text-align: center !important;
  }
}

.custom-input {
  display: grid !important;
  grid-template-columns: repeat(6, 1fr); /* Default for large screens (col-6) */
  justify-content: center;
  width: 10px !important;
  height: 100%;
  border: none;
  -moz-appearance: textfield !important;
}
.custom-input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.custom-input[type="number"]::-webkit-inner-spin-button,
.custom-input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Responsive styles */
@media (max-width: 600px) {
  /* Mobile devices (col-2) */
  .custom-input {
    grid-template-columns: repeat(3, 1fr);
    width: 40px;
  }
}
.custom-input::-webkit-outer-spin-button,
.custom-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.customProcessBar {
  .ant-progress-inner {
    height: 6px !important;
  }
  .ant-progress-bg {
    height: 6px !important;
    background: linear-gradient(to right, #ff5612, #ff8f61) !important;
  }
}

.drawerPaddingNone {
  .ant-drawer-body {
    padding: 0px !important;

    // opacity: 0.5 !important;
    // backdrop-filter: blur(10px) !important;
  }
}
.ant-drawer .ant-drawer-content {
  background-color: rgba(8, 8, 8, 0.8);
  // opacity: 0.75;
  backdrop-filter: blur(4px);
  z-index: 999; /* Adjust the value as needed */
}

.fullScreenModal {
  width: 100vw !important; /* Set width to full viewport width */
  height: 100vh !important; /* Set height to full viewport height */
  margin: 0 !important; /* Remove margin */
}

.hide-scrollbar {
  overflow: auto !important;
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; /* Internet Explorer 11 */
  &::-webkit-scrollbar {
    display: none !important; /* WebKit (Chrome, Safari, etc.) */
  }
}

.antdSelect {
  .ant-select-selector {
    border-radius: none !important;
    background-color: #080808 !important;
    color: #ffffff !important;
    border: 0px !important;
  }
  .ant-select {
    border: 0px !important;
  }
}
.ant-select {
  border: 0px !important;
  border-radius: 55px !important;
  background-color: #080808 !important;
}
.ant-select-selection-placeholder {
  color: #7c7c7c !important;
}
// ant-select ant-select-outlined rounded-xl border border-bgDarkGray  antdSelect antdSelectDropdown css-dev-only-do-not-override-qpimqy ant-select-single ant-select-show-arrow ant-select-show-search
// Range Picker

// .ant-picker-dropdown {

.ant-picker-dropdown .ant-picker-panel-container {
  @media (max-width: 480px) {
    max-width: 80vw !important;
    overflow: auto !important;
    inset: 51.9333px auto auto 0px !important;
  }
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  &,
  &:hover,
  &:focus,
  &:active {
    -webkit-box-shadow: 0 0 0px 1000px #080808 inset !important;
    -webkit-text-fill-color: white !important;
    -webkit-transition-delay: 99999s !important;
    -webkit-background-origin: 99999s !important;
  }
}

.ant-modal-wrap {
  &::-webkit-scrollbar {
    width: 0px !important;
  }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  &,
  &:hover,
  &:focus,
  &:active {
    -webkit-box-shadow: 0 0 0px 1000px #131313 inset !important;
    -webkit-text-fill-color: white !important;
    -webkit-transition-delay: 99999s !important;
    -webkit-background-origin: 99999s !important;
  }
}

.ant-modal-wrap {
  &::-webkit-scrollbar {
    width: 0px !important;
  }
}

// Scrollbar Arrow

::-webkit-scrollbar-button:single-button {
  background-color: #343434;

  display: block;
  background-size: 10px;
  background-repeat: no-repeat;
}
/* Up */
::-webkit-scrollbar-button:single-button:vertical:decrement {
  height: 16px;
  width: 16px; /* Maintain a minimum width for the button */
  padding: 10px;
  background-position: center 6px;
  background-size: 8px; /* Adjusted size of the background image */
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:disabled {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='50,00 0,50 100,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(255, 255, 255)'><polygon points='50,00 0,50 100,50'/></svg>") !important;
}

/* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  height: 16px;
  width: 16px;
  background-position: center 6px;
  background-size: 8px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 100,0 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 100,0 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='white'><polygon points='0,0 100,0 50,50'/></svg>");
}

/* Left */
::-webkit-scrollbar-button:single-button:horizontal:decrement {
  height: 12px;
  width: 12px;
  background-position: 3px 3px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,50 50,100 50,0'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,50 50,100 50,0'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>");
}

/* Right */
::-webkit-scrollbar-button:single-button:horizontal:increment {
  height: 12px;
  width: 12px;
  background-position: 3px 3px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 0,100 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>");
}
